<template>
  <div class="container" style="margin-top:150px;margin-bottom:50px;">
    <div class="container text-center">
      <h2 class="mb-5" style="color:#727272"><strong>We Can't find the page you requested</strong></h2>
      <p>You might have followed an incorrect link or mistyped an URL <br>But you can access our site by using the links provided below</p>
      <a href="/" class="btn btn-primary" style="color:white">Home</a>
    </div>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js'
import '../assets/hire.css'
export default {
  name: "pageNotFound",
  data() {
    return{
      action: new Actions(),
    }
  },
  mounted() {
    this.action.notFound().then(()=>{

    }).catch(()=>{

    })
  }
}
</script>